import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';

import profile from 'content/assets/profile.jpg';

// TODO: Tidy up the responsive styles
// TODO: Get this to use Gatsby image
// TODO: Expand on the content
const AboutPage = () => {
  return (
    <Layout title="About me">
      <SEO title="About Damian Gostomski" />

      <div className="flex flex-col md:flex-row items-center -m-4 sm:-m-16 md:-m-32">
        <img width={905} height={905} src={profile} className="block md:w-1/3 md:py-20 md:ml-20 lg:w-1/2 lg:p-0 lg:m-0"/>

        <div className="md:w-2/3 lg:w-1/2 p-4 sm:p-10 md:p-20">
          <p className="mb-4 text-xl leading-snug text-gray-700">I'm Damian Gostomski, a lead developer working at GoDaddy overseeing the React and Node engineers. I ensure that our platform and services are robust, performant and scalable, well architected and that the teams are able to work effectively to meet objectives.</p>
          <p className="">This site is about everything I've learned in over 15 years of development and all opinions expressed are my own and not those of GoDaddy.</p>
        </div>
      </div>
    </Layout>
  );
}

export default AboutPage;
